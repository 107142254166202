<template>
  <div>
    <div class="section section__faq">
      <div class="section__wrapper section__subsection-wrapper">
        <div class="title-with-icon mb-5">
          <div class="title-with-icon__icon title-with-icon__icon_faq"></div>
          <div class="title-with-icon__title">Часто задаваемые вопросы</div>
        </div>

        <div class="row">
          <div class="col-md-8">
            <div class="section__faq-wrapper">
              <div class="accordion" id="faq">
                <div v-for="question in questions" :key="question.id" class="accordion__item container-shadow">
                  <div class="accordion__header">
                    <h2 class="collapsed" data-toggle="collapse" :data-target="'#collapse-' + question.id" aria-expanded="false" :aria-controls="'collapse-' + question.id" :id="'heading-' + question.id">
                      {{ question.question }}
                    </h2>
                  </div>
                  <div :id="'collapse-' + question.id" class="collapse accordion__body" :aria-labelledby="'heading-' + question.id" data-parent="#faq">
                    <div class="section__course-text" v-html="question.answer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 mt-7 mt-md-0 d-none d-lg-block d-xl-block">
            <h2 class="mb-24 content_title">Остались вопросы?</h2>
            <p class="mb-md-4 mb-7">Специально для этого на нашем сайте работает обратная связь. Задайте Ваш вопрос и мы обязательно на него ответим!</p>

            <a href="#" class="link-target mb-8"><span>Задать вопрос</span></a>

            <h2 class="mb-24 content_title">Контакты</h2>
            <nav class="header__navigation-menu">
              <ul>
                <li><a href="mailto:info@exportedu.ru" class="header__navigation-menu-email">info@exportedu.ru</a></li>
                <li><a href="tel:info@exportedu.ru" class="header__navigation-menu-phone">+7 (495) 937-4742</a></li>
                <li><a href="#" class="header__navigation-menu-location">123610, Москва, Краснопресненская наб. 12, подъезд
                  9</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import ApiService from "../../services/api.service";
import PageFooter from "../components/PageFooter";

export default {
  name: "Faq",
  components: {PageFooter},
  data() {
    return {
      questions: null
    }
  },
  mounted() {
    this.$parent.init()
    ApiService.get('faqs', {
      params: {
        limit: 20
      }
    }).then(res => {
      this.questions = res.data.faqs
    })
  }
}
</script>

<style lang="scss" scoped>
.accordion__body {
  padding: 0;
  .section__course-text {
    padding: 2.4rem 0;
  }
}
.accordion__header {
  padding: 0 2.4rem 0 0;
  h2 {
    padding: 2.4rem 0 2.4rem 2.4rem;
  }
}
</style>